import { Button, Dropdown, Menu, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HibraryRootState } from '../../../redux/rootReducer';
import { ManageDevice } from '../../pages/devices';
import { getSignInSsoUrl } from '../../redux/auth/authCrud';
import { fetchNotificationData } from '../../redux/notification/actions';
import SVG from 'react-inlinesvg';
import LoginModal from '../modal/authModal/loginModal/LoginModal';
import ChangePasswordModal from '../modal/authModal/changePasswordModal/ChangePasswordModal';
import qs from 'qs';
import SignInSSOModal from '../modal/authModal/signInSSOModal/SignInSSOModal';
import { actions } from '../../redux/auth/authRedux';

interface LoginButtonParam {
    title?: any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    style?: {};
    userName?: any;
    isPlay?: boolean;
    isPlayPodcast?: boolean;
    isPlayPlayer?: boolean;
}

interface DropdownMenuType {
    label: string;
    icon: string;
    onClick: () => void;
}

export enum MenuDropdown {
    Profile = 'ข้อมูลส่วนตัว',
    Queue = 'การจองคิว',
    RentHistory = 'ประวัติการยืม',
    ChangePassword = 'เปลี่ยนรหัสผ่าน',
    Device = 'จัดการอุปกรณ์',
    UserSurvey = 'แบบสอบถามความพีงพอใจ',
    Sponsored = 'รายชื่อผู้สนับสนุน',
    VoteEbook = 'กิจกรรมโหวตอีบุ๊ก',
    Logout = 'ออกจากระบบ'
}

export const LoginButton = (props: LoginButtonParam) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loginModal, setLoginModal] = useState<boolean>(false);
    const [isModalChangedPW, setIsModalChangedPW] = useState<boolean>(false);
    const [isManageDevices, setIsManageDevices] = useState<boolean>(false);
    const [signInSSOModal, setSignInSSOModal] = useState<boolean>(false);
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const ssoSignOutToHomeList = [
        '/profile',
        '/history',
        '/voteEbook',
        '/bookshelf',
        '/listEbook',
        '/queue'
    ];
    const { authState, loginState, userInfo, clientInfo, notification } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            loginState: state.login,
            userInfo: state.auth.userInfo,
            clientInfo: state.clientInfo,
            notification: state.notification
        }),
        shallowEqual
    );

    const { authToken } = authState;

    useEffect(() => {
        if (authToken && loginModal) {
            setLoginModal(false);
        }

        if (authState.isLogin && !query.authenUserToken) {
            dispatch(fetchNotificationData());
        }
    }, [authToken, !query.authenUserToken]);

    useEffect(() => {
        if (loginModal !== loginState.showLogin) {
            setLoginModal(loginState.showLogin);
        }
    }, [loginState]);

    const handleLoginModal = () => {
        setLoginModal(true);
    };

    const signOutSS0 = () => {
        const isRedirectToHome = ssoSignOutToHomeList.some((el) =>
            window.location.pathname.includes(el)
        );
        let queryParam = `?redirect=${window.location.origin}/logout-sso`;
        if (isRedirectToHome) {
            queryParam = queryParam.concat(`?currentPath=/`);
        } else {
            queryParam = queryParam.concat(`?currentPath=${window.location.pathname}`);
        }

        let redirectPath = process.env.REACT_APP_REDIRECT_SSO_SIGNOUT!;
        redirectPath += queryParam;

        window.location.href = redirectPath;
    };

    const handleSignInButton = async () => {
        const isAcceptedPolicy = localStorage.getItem('accepted-policy') || false;
        if (clientInfo.companyPrefix === 'tat') {
            tatSSoSignIn();
            return;
        }
        if (clientInfo.sso) {
            if (clientInfo.strictPdpa && !isAcceptedPolicy) {
                setSignInSSOModal(true);
                // setLoginModal(true);
            } else {
                const response = await getSignInSsoUrl(clientInfo.companyPrefix);
                if (response.data) window.location.href = response.data?.ssoUrl;
            }
        } else {
            setLoginModal(true);
        }
    };

    const tatSSoSignIn = async () => {
        switch (process.env.REACT_APP_ENV) {
            case 'production':
            case 'staging':
                window.location.href =
                    'https://auth.tat.or.th/oauth2/sign_in?rd=https://elibrary-tat.hibrary.me';
                break;
            case 'development':
                window.location.href =
                    'https://time-auth.tat.or.th/oauth2/sign_in?rd=https://dev-elibrary.tat.or.th';
                break;
            default:
                break;
        }
    };

    const OnSignOut = () => {
        if (clientInfo.sso && clientInfo.companyPrefix !== 'tat') {
            signOutSS0();
        } else {
            history.push(`/logout`);
        }
    };

    const profileDropDown = () => {
        const menuArray: DropdownMenuType[] = [
            {
                label: intl.formatMessage({
                    id: 'Menu.ProfileBtn'
                }) as MenuDropdown,
                icon: `/images/dropdown-profile.svg`,
                onClick: () => history.push('/profile')
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.BookingBtn'
                }) as MenuDropdown,
                icon: `/images/dropdown-queue.svg`,
                onClick: () => history.push('/queue')
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.BorrowingHistoryBtn'
                }) as MenuDropdown,
                icon: `/images/dropdown-rentalHistory.svg`,
                onClick: () => history.push('/history')
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.ChangePasswordBtn'
                }) as MenuDropdown,
                icon: `/images/dropdown-changePW.svg`,
                onClick: () => setIsModalChangedPW(true)
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.ManageDeviceBtn'
                }) as MenuDropdown,
                icon: `/images/dropdown-device.svg`,
                onClick: () => setIsManageDevices(true)
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.SatisfactionSurveyBtn'
                }) as MenuDropdown,
                icon: `/images/icon/form-icon.svg`,
                onClick: () =>
                    window.open(notification.userSurveyUrl ?? `https://forms.gle/xYqhJyYhMtdzxSk2A`)
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.SponsorBtn'
                }) as MenuDropdown,
                icon: `/images/icon/sponsored.svg`,
                onClick: () => history.push('/viewAllSponsor')
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.VoteActivityBtn'
                }) as MenuDropdown,
                icon: `/images/icon/vote.svg`,
                onClick: () => history.push('/voteEbook')
            },
            {
                label: intl.formatMessage({
                    id: 'Menu.SignOutBtn'
                }) as MenuDropdown,
                icon: `/images/dropdown-logout.svg`,
                onClick: OnSignOut
            }
        ];

        return (
            <Menu>
                {menuArray.map((el) => {
                    if (
                        (el.label ===
                            (intl.formatMessage({
                                id: 'Menu.ChangePasswordBtn'
                            }) as MenuDropdown) &&
                            authState.userInfo?.isSsoLogin) ||
                        (el.label ===
                            (intl.formatMessage({
                                id: 'Menu.SatisfactionSurveyBtn'
                            }) as MenuDropdown) &&
                            !notification.userSurveyUrl) ||
                        (el.label ===
                            (intl.formatMessage({
                                id: 'Menu.SponsorBtn'
                            }) as MenuDropdown) &&
                            !notification.isSponsoredClient)
                    ) {
                        return null;
                    } else {
                        return (
                            <Menu.Item
                                key={el.label}
                                onClick={el.onClick}
                                className='dropdown-item'
                            >
                                <SVG src={el.icon} width={24} height={24} />
                                <span className='dropdown-text'>{el.label}</span>
                            </Menu.Item>
                        );
                    }
                })}
            </Menu>
        );
    };

    return (
        <>
            {authToken ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                    }}
                >
                    <span className='username'>{userInfo?.name + ' ' + userInfo?.surname}</span>
                    <Dropdown
                        overlay={profileDropDown()}
                        trigger={['click']}
                        placement='bottomRight'
                        overlayClassName='antDropDownNav'
                    >
                        <SVG src='/images/hamburger-button.svg' className='hamburger-button' />
                    </Dropdown>
                </div>
            ) : props.isPlay ? (
                <button
                    className='video-react-big-play-button video-react-big-play-button-center'
                    type='button'
                    aria-live='polite'
                    tabIndex={0}
                    onClick={handleLoginModal}
                >
                    <span className='video-react-control-text'>Play Video</span>
                </button>
            ) : props.isPlayPodcast ? (
                <Button className='ep-button' shape='circle' onClick={handleLoginModal}>
                    <div className='episode-player-stop'>
                        <img src={'/images/player/play-circle.svg'} />
                    </div>
                </Button>
            ) : props.isPlayPlayer ? (
                <Button className='player-button' onClick={handleLoginModal}>
                    <img src={'/images/player/play.svg'} />
                </Button>
            ) : (
                <button
                    className={`login-button ${props.title ? 'fontWeight-400' : 'fontWeight-500'}`}
                    style={props.style ? props.style : {}}
                    onClick={() => handleSignInButton()}
                >
                    {props.title
                        ? props.title
                        : intl.formatMessage({
                              id: 'SignIn.SignInBtn'
                          })}
                </button>
            )}
            <LoginModal visible={loginModal} onClose={() => setLoginModal(false)} />
            <ChangePasswordModal
                visible={isModalChangedPW}
                onClose={() => setIsModalChangedPW(false)}
            />
            <SignInSSOModal visible={signInSSOModal} onClose={() => setSignInSSOModal(false)} />
            <Modal
                destroyOnClose
                footer={null}
                visible={isManageDevices}
                onCancel={() => {
                    setIsManageDevices(false);
                }}
                title={intl.formatMessage({ id: 'Menu.ManageDeviceBtn' })}
                maskClosable={false}
            >
                <ManageDevice />
            </Modal>
        </>
    );
};
