/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useMemo, memo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { Helmet } from 'react-helmet-async';
import { MemoEbook, MemoVideo, MemoPodcast } from './content';
import { removeSplash } from '../../helpers/removeSplash';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { setReadingGoal } from '../../redux/clientInfo/action';
import { setCookie } from '../../helpers/cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { requestFromServer, rentReset } from '../../redux/rental/action';
import { EmptyDataView } from '../../components/nodata';
import { showErrorModal } from '../../components/modal/errorModal';
import { getDetailRoute } from '../../routes/generatePath';
import { isMobile } from 'react-device-detect';
import {
    compareAddedDate,
    compareExpiredDate,
    compareTitle,
    compareAuthorName,
    compareReadingTime
} from './custom-sort';
import {
    generateContent,
    getLicense,
    getReaderContentUrl,
    getReadingTime
} from '../../redux/bookshelf/request';
import {
    AxiosError,
    ContentType,
    License,
    LicenseResponse,
    RentalStatus
} from '../../../interface';
import {
    breakpointEbook,
    breakpointPodcast,
    breakpointVideo
} from '../../Utilities/swiperBreakpoint';

import dayjs from 'dayjs';
import GoalModal from '../../components/modal/readGoalModal/goalModal';
import { SegmentButton } from '../../components/bookShelfSegmented';
import { useIntl } from 'react-intl';

enum SortContent {
    AddDate,
    ReadingDate,
    Tile,
    ExpireDate,
    AuthorName
}

// const sortWording = (sortBy: SortContent) => {
//     switch (sortBy) {
//         case SortContent.AddDate:
//             return 'เพิ่มล่าสุด';
//         case SortContent.ReadingDate:
//             return 'อ่านล่าสุด';
//         case SortContent.ExpireDate:
//             return 'วันหมดอายุ';
//         case SortContent.Tile:
//             return 'ชื่อหนังสือ';
//         case SortContent.AuthorName:
//             return 'นักเขียน';
//         default:
//             break;
//     }
// };

const sortByFunc = (sortBy: SortContent) => {
    switch (sortBy) {
        case SortContent.AddDate:
            return compareAddedDate;
        case SortContent.ReadingDate:
            return compareReadingTime;
        case SortContent.ExpireDate:
            return compareExpiredDate;
        case SortContent.Tile:
            return compareTitle;
        case SortContent.AuthorName:
            return compareAuthorName;
        default:
            break;
    }
};

const timeConverter = (sec: number) => {
    const intl = useIntl();
    const totalMinute = Math.ceil(sec / 60);
    const hour = Math.floor(totalMinute / 60);
    const minute = totalMinute % 60;
    const seconds = sec % 60;

    //   `  ${minute === 1 ? '' : intl.formatMessage({ id: 'Unit.Sec' })}`;
    const minuteWord = minute > 0 ? `${minute} ${intl.formatMessage({ id: 'Unit.Min' })}` : null;
    const hourWord = hour > 0 ? `${hour} ${intl.formatMessage({ id: 'Unit.Hour' })}` : null;
    const secondWord = seconds > 0 ? `${seconds} ${intl.formatMessage({ id: 'Unit.Sec' })}` : null;

    return `${hourWord} ${minuteWord} ${secondWord}`;
};

export const BookshelfPage = () => {
    const pageId = 'BookshelfPage';
    const history = useHistory();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [goalModal, setGoalModal] = useState<boolean>(false);
    const [bookshelfContent, setBookshelfContent] = useState<LicenseResponse>();
    const [viewContentType, setViewContentType] = useState(ContentType.ebook);
    const [processingLicense, setProcessingLicense] = useState<License>();
    const [filterReadingNow, setFilterReadingNow] = useState<License[]>([]);
    const [sortBy, setSortBy] = useState<SortContent>(SortContent.AddDate);
    const [totalTime, setTotalTime] = useState(0);
    const [currentDisplayContent, setCurrentDisplayContent] = useState<License[]>();
    const [readingNowList, setReadingNowList] = useState<License[]>(getReadingNow() ?? []);

    const { authState, readingGoal } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            readingGoal: state.clientInfo.readingGoal
        }),
        shallowEqual
    );

    useEffect(() => {
        if (authState.isLogin) {
            fetchLicenses();
            fetchReadingTime();
        } else {
            history.push('/error/not-found');
        }
    }, []);

    useEffect(() => {
        if (bookshelfContent) {
            setCurrentDisplayContent(
                filterAndSortLicense(bookshelfContent?.licenses ?? [], viewContentType, sortBy) ??
                    []
            );
        }
    }, [bookshelfContent, viewContentType, sortBy]);

    useEffect(() => {
        if (currentDisplayContent) {
            const filetedReading =
                getFilterReadingNow(readingNowList, currentDisplayContent, viewContentType) ?? [];
            const newReading: License[] = [];
            setFilterReadingNow(newReading.concat(filetedReading));
        }
    }, [readingNowList, currentDisplayContent, viewContentType]);

    const fetchLicenses = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const data = await getLicense();
            const newMapping = mapReadingTimeToLicenses(data?.licenses ?? [], readingNowList);
            if (data) {
                if (data.readingGoal) dispatch(setReadingGoal(data.readingGoal));
                checkExistContent(data.licenses!);
                setBookshelfContent({ licenses: newMapping });
                dispatch(removeSplash);
                dispatch(action.setProcess(false, pageId));
            }
        } catch (error) {
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        }
    };

    const ModalInfo = () => {
        Modal.info({
            title: intl.formatMessage({ id: 'BookShelf.ModalReadingTitle' }),
            content: (
                <p style={{ marginBottom: '0' }}>
                    {intl.formatMessage({
                        id: 'BookShelf.ModalReadingSubtitle'
                    })}
                </p>
            )
        });
    };

    const fetchReadingTime = async () => {
        try {
            const data = await getReadingTime();
            setTotalTime(data?.totalTime ?? 0);
        } catch (error) {
            console.log(error);
        }
    };

    const onOpenContent = (licenseUID: string) => {
        if (isMobile && viewContentType === ContentType.ebook) {
            ModalInfo();
        } else {
            const getIndex = currentDisplayContent!.findIndex((el) => el.licenseUID === licenseUID);
            const license = currentDisplayContent![getIndex];
            if (
                [ContentType.ebook, ContentType.magazine, ContentType.newspaper].includes(
                    license.contentType
                )
            ) {
                if (!processingLicense) processContent(license);
            } else {
                addReadingNow(license);
                history.push(
                    getDetailRoute(
                        license.contentUID,
                        license.contentType ?? ContentType.ebook,
                        license.licenseType
                    )
                );
            }
        }
    };

    const checkExistContent = (license: License[]) => {
        if (license.filter((el) => el.contentType === ContentType.ebook).length > 0) {
            if (viewContentType !== ContentType.ebook) {
                setViewContentType(ContentType.ebook);
            }
        } else if (license.filter((el) => el.contentType === ContentType.video).length > 0) {
            setViewContentType(ContentType.video);
        } else if (license.filter((el) => el.contentType === ContentType.podcast).length > 0) {
            console.log(ContentType.podcast);
            setViewContentType(ContentType.podcast);
        }
    };

    const processContent = async (license: License) => {
        try {
            setProcessingLicense(license);
            dispatch(action.setProcess(true, license.licenseUID));
            const data = await getReaderContentUrl(authState.userInfo?.clientUID ?? '', license);
            const content = await generateContent(
                authState.userInfo?.clientUID ?? '',
                license,
                data!.requestURL,
                data!.clientKey,
                window.location.href
            );
            dispatch(action.setProcess(false, license.licenseUID));
            setProcessingLicense(undefined);
            addReadingNow(license);
            openReaderApp(content?.readerURL ?? '');
        } catch (err) {
            const error = err as AxiosError;
            dispatch(action.setProcess(false, license.licenseUID));
            setProcessingLicense(undefined);
            showErrorModal(error);
            if (error.response && error.response.data && error.response.data.errorCode === '905') {
                onReturnContent(license.licenseUID, false);
            }
        }
    };

    const openReaderApp = (readerURL: string) => {
        const urlString = window.location.href;
        setCookie('HTIReaderToken', authState.authToken!, 1);
        setCookie('HTIReaderReturnUrl', urlString, 1);
        setTimeout(() => {
            window.location.href = readerURL;
        }, 300);
    };

    const mapReadingTimeToLicenses = (licenses: License[], readingTimes: License[]) => {
        const newMapData: License[] = [];
        licenses.forEach((data) => {
            const getIndex = readingTimes.findIndex((el) => el.licenseUID === data.licenseUID);
            if (getIndex >= 0) {
                const getData = { ...readingTimes[getIndex] };
                const newData = { ...data };
                newData.lastReading = getData.lastReading;
                newMapData.push({ ...newData });
            } else {
                data.lastReading = 0;
                newMapData.push({ ...data });
            }
        });
        return newMapData;
    };

    const addReadingNow = (license: License) => {
        const newReading = addReadingNowToLocalStorage(license, readingNowList);
        const newArray: License[] = [];
        setReadingNowList(newArray.concat(newReading));
        const getIndex = bookshelfContent?.licenses.findIndex(
            (el) => el.licenseUID === license.licenseUID
        );
        if (getIndex! >= 0) {
            const getLicense = bookshelfContent?.licenses[getIndex!];
            getLicense!.lastReading = dayjs().unix();
            setBookshelfContent({ ...bookshelfContent! });
        }
    };

    const removeLicense = (licenseUID: string) => {
        const getIndex = bookshelfContent?.licenses.findIndex((el) => el.licenseUID === licenseUID);
        if (getIndex! >= 0) {
            bookshelfContent?.licenses.splice(getIndex!, 1);
        }
        setBookshelfContent({ ...bookshelfContent! });
    };

    const removeReadingNow = (licenseUID: string) => {
        const getIndex = currentDisplayContent!.findIndex((el) => el.licenseUID === licenseUID);
        const license = currentDisplayContent![getIndex];
        const newReading = removeReadingNowFromLocalStorage(license, readingNowList ?? []);
        const newArray: License[] = [];

        setReadingNowList(newArray.concat(newReading));
    };

    const onReturnContent = (licenseUID: string, fetch = true) => {
        const getIndex = currentDisplayContent!.findIndex((el) => el.licenseUID === licenseUID);
        const license = currentDisplayContent![getIndex];
        if (fetch) {
            fetchReturnContent(license);
        } else {
            removeLicense(license.licenseUID);
            removeReadingNow(license.licenseUID);
        }
    };

    const fetchReturnContent = async (license: License) => {
        try {
            dispatch(action.setProcess(true, license.licenseUID));
            await requestFromServer(license.licenseUID, RentalStatus.Rented, license.licenseType);
            dispatch(rentReset());
            removeLicense(license.licenseUID);
            removeReadingNow(license.licenseUID);
            dispatch(action.setProcess(false, license.licenseUID));
        } catch (error) {
            showErrorModal(error as any);
            dispatch(action.setProcess(false, license.licenseUID));
        }
    };

    const generateClass = () => {
        switch (viewContentType) {
            case ContentType.ebook:
                return 'bookshelf-content-ebook';
            case ContentType.video:
                return 'bookshelf-content-video';
            case ContentType.podcast:
                return 'bookshelf-content-podcast';
            default:
                return 'bookshelf-content-ebook';
        }
    };

    const getContentListView = useMemo(() => {
        // eslint-disable-next-line array-callback-return
        return currentDisplayContent?.map((el, index) => {
            switch (viewContentType) {
                case ContentType.ebook:
                    return (
                        <MemoEbook
                            data={el}
                            key={`${el.licenseUID}-${index}list-MemoEbook`}
                            onOpenContent={onOpenContent}
                            onReturnContent={onReturnContent}
                        />
                    );
                case ContentType.podcast:
                    return (
                        <MemoPodcast
                            data={el}
                            key={`${el.licenseUID}-${index}list-MemoPodcast`}
                            onOpenContent={onOpenContent}
                            onReturnContent={onReturnContent}
                        />
                    );
                case ContentType.video:
                    return (
                        <MemoVideo
                            data={el}
                            key={`${el.licenseUID}-${index}list-MemoVideo`}
                            onOpenContent={onOpenContent}
                            onReturnContent={onReturnContent}
                        />
                    );
            }
        });
    }, [viewContentType, currentDisplayContent]);

    const filterAndSortLicense = (
        license: License[],
        viewType: ContentType,
        sortBy: SortContent
    ) => {
        const filterData = license
            .filter((el) => {
                // el.contentType === viewType;
                if (viewType === ContentType.ebook) {
                    if (
                        el.contentType === ContentType.ebook ||
                        el.contentType === ContentType.magazine ||
                        el.contentType === ContentType.newspaper
                    )
                        return true;
                    else return false;
                } else if (viewType === ContentType.video) {
                    return el.contentType === ContentType.video;
                } else if (viewType === ContentType.podcast) {
                    return el.contentType === ContentType.podcast;
                } else return false;
            })
            .sort(sortByFunc(sortBy));
        return filterData;
    };

    const onSegmentSelect = (index: number) => {
        switch (index) {
            case 0:
                setViewContentType(ContentType.ebook);
                break;
            case 1:
                setViewContentType(ContentType.video);
                break;
            case 2:
                setViewContentType(ContentType.podcast);
                break;
            default:
                break;
        }
    };

    const getFilterReadingNow = (
        readingData: License[],
        compareData: License[],
        contentType: ContentType
    ) => {
        const filterData = readingData?.filter((el) => {
            const index = compareData?.findIndex((data) => data.licenseUID === el.licenseUID);
            if (index! >= 0) {
                return true;
            } else {
                return false;
            }
        });

        return filterData!
            .sort((a, b) => b.lastReading! - a.lastReading!)
            .filter((el) => el.contentType === contentType);
    };

    const readingNow = useMemo(() => {
        return (
            <ReadingNowView
                onOpenContent={onOpenContent}
                onReturnContent={onReturnContent}
                licenses={filterReadingNow}
                contentType={viewContentType}
            />
        );
    }, [filterReadingNow, viewContentType]);

    const memoListContent = useMemo(() => {
        if (currentDisplayContent) {
            return (
                <>
                    {currentDisplayContent.length > 0 ? (
                        <div className={generateClass()}>{getContentListView}</div>
                    ) : (
                        <EmptyDataView
                            description={intl.formatMessage({
                                id: 'Action.NoDataFound'
                            })}
                        />
                    )}
                </>
            );
        }
    }, [currentDisplayContent]);

    const getSegmentIndex = (contentType: ContentType) => {
        switch (contentType) {
            case ContentType.ebook:
                return 0;
            case ContentType.video:
                return 1;
            case ContentType.podcast:
                return 2;
            default:
                return 0;
        }
    };

    return (
        <>
            <div
                className={`bookShelf ${
                    currentDisplayContent && currentDisplayContent!.length === 0 ? 'emptyView' : ''
                }`}
            >
                <Helmet>
                    <title>
                        {window.location.hostname.match(/cu-elibrary.com/)
                            ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                            : 'Hibrary'}{' '}
                        | ชั้นหนังสือ
                    </title>
                </Helmet>
                <SegmentButton
                    // onSelected={setViewContentType}
                    buttonTitles={[
                        intl.formatMessage({
                            id: 'ContentType.Ebook'
                        }),
                        intl.formatMessage({
                            id: 'ContentType.Video'
                        }),
                        intl.formatMessage({
                            id: 'ContentType.Podcast'
                        })
                    ]}
                    defaultIndex={getSegmentIndex(viewContentType)}
                    onSelected={onSegmentSelect}
                />
                {filterReadingNow.length > 0 && readingNow}
                <div className='bookShelf-header'>
                    <div className='header'>
                        <h1>
                            {intl.formatMessage(
                                { id: 'BookShelf.Book' },
                                { 0: currentDisplayContent?.length ?? 0 }
                            )}
                        </h1>
                    </div>
                    {totalTime > 0 ? (
                        <div className='reading-time'>
                            <p className='grey-dark'>
                                {intl.formatMessage(
                                    { id: 'BookShelf.TotalReadTime' },
                                    {
                                        0: (
                                            <span className='grey-light'>
                                                {timeConverter(totalTime)}
                                            </span>
                                        )
                                    }
                                )}
                            </p>
                            {viewContentType === ContentType.ebook && (
                                <p className='goal-button' onClick={() => setGoalModal(true)}>
                                    {intl.formatMessage(
                                        {
                                            id: 'BookShelf.ReadindGoal'
                                        },
                                        {
                                            0: convertFormatReadingGoal((readingGoal ?? 600) / 60)
                                        }
                                    ) + '/'}
                                    {intl.formatMessage({
                                        id: 'Unit.Day'
                                    })}
                                </p>
                            )}
                        </div>
                    ) : null}
                </div>
                {memoListContent}
            </div>
            <GoalModal visible={goalModal} onClose={() => setGoalModal(false)} />
            <Loading id={pageId} />
        </>
    );
};

interface ReadingNowViewProps {
    licenses: License[];
    contentType: ContentType;
    onOpenContent: (licenseUID: string) => void;
    onReturnContent: (licenseUID: string) => void;
}

const ReadingNowView: React.FC<ReadingNowViewProps> = ({
    licenses,
    contentType,
    onOpenContent,
    onReturnContent
}) => {
    const getSlidePerView = (viewType: ContentType) => {
        switch (viewType) {
            case ContentType.ebook:
                return breakpointEbook;
            case ContentType.podcast:
                return breakpointPodcast;
            case ContentType.video:
                return breakpointVideo;
            default:
                return breakpointEbook;
        }
    };

    const getContentListView = () => {
        return licenses?.map((el, index) => {
            switch (el.contentType) {
                case ContentType.ebook:
                    return (
                        <SwiperSlide
                            style={{ width: 180 }}
                            className='ebook'
                            key={`${el.licenseUID}-${index}SwiperSlide`}
                        >
                            <MemoEbook
                                key={`${el.licenseUID}-${index}MemoEbook`}
                                data={el}
                                onOpenContent={onOpenContent}
                                onReturnContent={onReturnContent}
                            />
                        </SwiperSlide>
                    );
                case ContentType.podcast:
                    return (
                        <SwiperSlide
                            key={`${el.licenseUID}-${index}SwiperSlide`}
                            className='podcast'
                            style={{ width: 250 }}
                        >
                            <MemoPodcast
                                key={`${el.licenseUID}-${index}MemoPodcast`}
                                data={el}
                                onOpenContent={onOpenContent}
                                onReturnContent={onReturnContent}
                            />
                        </SwiperSlide>
                    );
                case ContentType.video:
                    return (
                        <SwiperSlide
                            key={`${el.licenseUID}-${index}SwiperSlide`}
                            className='video'
                            style={{ width: 350 }}
                        >
                            <MemoVideo
                                key={`${el.licenseUID}-${index}MemoVideo`}
                                data={el}
                                onOpenContent={onOpenContent}
                                onReturnContent={onReturnContent}
                            />
                        </SwiperSlide>
                    );
                default:
                    return <div></div>;
            }
        });
    };
    return (
        <>
            <h1>Reading Now</h1>
            <div className='reading-swiper'>
                <MemoSwiper
                    className='reading-now-swiper'
                    spaceBetween={16}
                    centeredSlides={false}
                    loop={false}
                    breakpoints={getSlidePerView(contentType)}
                >
                    {getContentListView()}
                </MemoSwiper>
            </div>
        </>
    );
};

export const MemoSwiper = memo(Swiper);

const readingNowKey = 'readingNow-Hibrary';

const getReadingNow = () => {
    try {
        const data = JSON.parse(localStorage.getItem(readingNowKey) ?? '') as License[];
        return data;
    } catch (error) {
        return [];
    }
};

const setReadingNow = (readingData: License[]) => {
    localStorage.setItem(readingNowKey, JSON.stringify(readingData));
};

const addReadingNowToLocalStorage = (readingData: License, rawReadingData: License[]) => {
    const index = rawReadingData.findIndex((el) => el.licenseUID === readingData.licenseUID);
    const sortData = rawReadingData.sort((a, b) => a.lastReading! - b.lastReading!);
    if (index < 0) {
        readingData.lastReading = dayjs().unix();
        rawReadingData.push(readingData);
    } else {
        rawReadingData[index].lastReading = dayjs().unix();
    }
    setReadingNow(sortData);
    return sortData;
};

const removeReadingNowFromLocalStorage = (readingData: License, rawReadingData: License[]) => {
    const index = rawReadingData.findIndex((el) => el.licenseUID === readingData.licenseUID);
    if (index >= 0) {
        const sortData = rawReadingData.sort((a, b) => a.lastReading! - b.lastReading!);
        rawReadingData.splice(index, 1);
        setReadingNow(sortData);
        return sortData;
    }
    return rawReadingData;
};

export const convertFormatReadingGoal = (time: number) => {
    const intl = useIntl();
    let string = '';

    if (time / 60 >= 1) {
        string +=
            `${Math.floor(time / 60)} ${intl.formatMessage({
                id: 'Unit.Hour'
            })}` + ' ';
    }
    if (time % 60 >= 1) {
        string += `${Math.floor(time % 60)} ${intl.formatMessage({
            id: 'Unit.Min'
        })}`;
    }

    return string;
};
